import React, { useState } from "react"
import "./ProfileScreen.css"
import Nav from "../components/Nav"
import { useSelector } from "react-redux"
import { getUser } from "../app/features/userSlice"
import requests from "../api/requests"
import { useSnackbar } from "react-simple-snackbar"
import { errorSnackBar, successSnackBar } from "../components/snackBarOptions"
import { useNavigate } from "react-router-dom"
import FormInput from "../components/FormInput"
import Button from "../components/Button"

function ProfileScreen() {
  const user = useSelector(getUser)
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const [openSuccessSnackbar] = useSnackbar(successSnackBar)
  const navigate = useNavigate()

  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  })

  const [isLoading, setIsLoading] = useState(false)

  const inputs = [
    {
      id: 1,
      name: "password",
      type: "password",
      placeholder: "Nova Senha",
      errorMessage: "Mínimo de 8 caracteres",
      label: "Senha",
      pattern: ".{8,}",
      required: true,
    },
    {
      id: 2,
      name: "confirmPassword",
      type: "password",
      placeholder: "Repita a nova senha",
      errorMessage: "As senhas não são iguais",
      label: "Repita a senha",
      pattern: values.password,
      required: true,
    },
  ]

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  if (!user) {
    navigate("/")
  }

  const handleUpdatePassword = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await requests.updatePassword(values)
      openSuccessSnackbar("Senha alterada com sucesso")
      setIsLoading(false)
      navigate("/")
    } catch (error) {
      openErrorSnackbar("Ocorreu um erro inexperado")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="profileScreen">
      <Nav />
      <div className="profileScreen__body">
        <h1>{`Olá ${user?.name}!`}</h1>
        <div className="profileScreen__info">
          <img
            src={`https://ui-avatars.com/api/?name=${user?.name}&background=BAB5C8&size=128`}
            alt=""
          />
          <div className="profileScreen__details">
            <h2>{user?.email}</h2>
            <div className="profileScreen__form">
              <h3>Digite a nova senha</h3>
              <form onSubmit={handleUpdatePassword} noValidate autoComplete="off">
                {inputs.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                ))}
                <Button text="Alterar Senha" isLoading={isLoading} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileScreen
