import React, { useState } from "react"
import "./Row.css"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { useNavigate } from "react-router-dom"

function Row({ index, refFunc, title, contents = [] }) {
  const [scrollX, setScrollX] = useState(0)
  const navigate = useNavigate()

  const baseURL = process.env.REACT_APP_CONTENT_URL

  const handleLeftArrow = () => {
    let x = scrollX + Math.round(window.innerWidth / 2)
    if (x > 0) {
      x = 0
    }

    setScrollX(x)
  }

  const handleRightArrow = () => {
    let x = scrollX - Math.round(window.innerWidth / 2)
    const listW = contents.length * 320
    if (window.innerWidth - listW > x) {
      x = window.innerWidth - listW - 80
    }

    setScrollX(x)
  }
  const shouldScroll = contents.length * 320 > window.innerWidth

  const handleImageClick = (content) => {
    navigate(`/watch`, { state: { content } })
  }

  return (
    <div key={index} ref={refFunc} className="row">
      <h2>{title}</h2>
      {shouldScroll && (
        <div>
        <div className="row__left" onClick={handleLeftArrow}>
          <NavigateBeforeIcon style={{ fontSize: 50 }} />
        </div>
        <div className="row__right" onClick={handleRightArrow}>
          <NavigateNextIcon style={{ fontSize: 50 }} />
        </div>
        </div>
      )}
      <div className="row__posters" style={{ marginLeft: scrollX }}>
        {contents.map((content, i) => (
          <div key={i} className="row__posterContainer">
            <img
              className="row__image"
              src={encodeURI(
                `${baseURL}/${content?.thumbnail?.sizes?.poster?.filename}`
              )}
              alt={content?.thumbnail?.alt}
              onClick={() => handleImageClick(content)}
            />
            <div className="row__progress" style={{width: `${content?.watched_percentage * 100}%`}}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Row
