import React, { useEffect, useState } from "react"
import "./OnboardingScreen.css"
import requests from "../api/requests"
import { login } from "../app/features/userSlice"
import { useDispatch } from "react-redux"
import { useSnackbar } from "react-simple-snackbar"
import {
  successSnackBar,
  errorSnackBar,
  DEFAULT_ERROR_MESSAGE,
} from "../components/snackBarOptions"
import FormInput from "../components/FormInput"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { set } from "react-hook-form"
import Button from "../components/Button"

function RecoverPasswordScreee() {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [hasRecoverToken, setHasRecoverToken] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { token } = useParams()

  const [values, setValues] = useState({
    email: location?.state?.email || "",
    password: "",
    confirmPassword: "",
  })

  const [openSuccessSnackbar] = useSnackbar(successSnackBar)
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)

  const recoverInputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Preencha com um email válido",
      label: "Email",
      required: true,
    },
  ]

  const changePasswordInputs = [
    {
      id: 3,
      name: "password",
      type: "password",
      placeholder: "Senha",
      errorMessage: "A senha deve ter de 8 à 20 caracteres",
      label: "Senha",
      pattern: `^[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 5,
      name: "confirmPassword",
      type: "password",
      placeholder: "Repita a senha",
      errorMessage: "As senhas não são iguais",
      label: "Repita a senha",
      pattern: values.password,
      required: true,
    },
  ]

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const checkRecoverToken = async () => {
      try {
        await requests.getResetPassword(token)
      } catch (error) {
        openErrorSnackbar("Link de recuperação inválido")
        navigate("/")
      }
    }

    if (token) {
      setHasRecoverToken(true)
      checkRecoverToken()
    }
  }, [token])

  const sendRecoverEmail = async (e) => {
    e.preventDefault()
    const formElement = e.target
    const isValid = formElement.checkValidity()
    if (!isValid) {
      openErrorSnackbar("Preencha os campos solicitados")
      return
    }

    try {
      setIsLoading(true)
      await requests.createResetPassword({ email: values.email })
      openSuccessSnackbar("Email enviado com sucesso")
    } catch (error) {
      openErrorSnackbar(DEFAULT_ERROR_MESSAGE)
    } finally {
      setIsLoading(false)
    }
  }

  const changePassword = async (e) => {
    e.preventDefault()
    const formElement = e.target
    const isValid = formElement.checkValidity()
    if (!isValid) {
      openErrorSnackbar("Preencha os campos solicitados")
      return
    }

    try {
      setIsLoading(true)
      const resp = await requests.resetPassword(token, {
        password: values.password,
        confirmPassword: values.confirmPassword,
      })

      dispatch(login(resp.data))
      navigate("/")
    } catch (error) {
      openErrorSnackbar(DEFAULT_ERROR_MESSAGE)
    } finally {
      setIsLoading(false)
    }
  }

  const recoverForm = (
    <form onSubmit={sendRecoverEmail} noValidate autoComplete="off">
      {recoverInputs.map((input) => (
        <FormInput
          key={input.id}
          {...input}
          value={values[input.name]}
          onChange={onChange}
        />
      ))}
      <Button text="Enviar E-mail" isLoading={isLoading} />
    </form>
  )

  const changePasswordForm = (
    <form onSubmit={changePassword} noValidate autoComplete="off">
      {changePasswordInputs.map((input) => (
        <FormInput
          key={input.id}
          {...input}
          value={values[input.name]}
          onChange={onChange}
        />
      ))}
      <Button text="Redefinir Senha" isLoading={isLoading} />
    </form>
  )

  const form = hasRecoverToken ? changePasswordForm : recoverForm
  const h1 = hasRecoverToken ? "Redefina sua senha" : "Recupere sua senha"
  const h2 = hasRecoverToken ? "Preencha com sua nova senha" : "Preencha com seu email"

  return (
    <div className="onboardingScreen">
      <div className="onboardingScreen__background"></div>
      <div className="onboardingScreen__gradient"></div>
      <div className="onboardingScreen__body">
        <div className="onboardingScreen__form">
          <h1>{h1}</h1>
          <h2>{h2}</h2>
          {form}
        </div>
      </div>
    </div>
  )
}

export default RecoverPasswordScreee
