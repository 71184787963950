import React, { useState } from "react"
import Button from "../components/Button"
import "./OnboardingScreen.css"
import "./UnpaidScreen.css"
import requests from "../api/requests"
import { errorSnackBar } from "../components/snackBarOptions"
import { useSnackbar } from "react-simple-snackbar"

function UnpaidScreen() {
  const [isLoading, setIsLoading] = useState(false)
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)

  const loadPortal = async () => {
    let resp = null
    try {
      setIsLoading(true)
      resp = await requests.createPortalSession({
        returnURL: window.location.origin,
      })
    } catch (error) {
      openErrorSnackbar("Erro ao acessar o portal de pagamento")
    } finally {
      setIsLoading(false)
    }

    if (resp?.data?.url) {
      window.open(resp?.data?.url, "_blank")
    }
  }
  return (
    <div className="onboardingScreen">
      <div className="onboardingScreen__background"></div>
      <div className="onboardingScreen__gradient"></div>
      <div className="onboardingScreen__body">
        <div className="unpaidScreen">
          <h1>Não conseguimos processar seu pagamento</h1>
          <p>Para continuar acessando o Cosmo Clube, por favor atualize a forma de pagamento clicando no botão abaixo</p>
          <Button onClick={e => loadPortal()} text="Atualizar Pagamento" isLoading={isLoading}/>
        </div>
      </div>
    </div>
  )
}

export default UnpaidScreen
