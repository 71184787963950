const successSnackBar = {
  position: 'top-center',
  style: {
    backgroundColor: '#44795A',
    color: '#F2E1D1',
    fontSize: '15px',
    textAlign: 'center',
  },
}

const errorSnackBar = {
  position: 'top-center',
  style: {
    backgroundColor: '#ff4d4d',
    color: 'white',
    fontSize: '15px',
    textAlign: 'center',
  },
}

const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro inexperado'

export {
  successSnackBar,
  errorSnackBar,
  DEFAULT_ERROR_MESSAGE
}