import HomeScreen from './screens/HomeScreen'
import './App.css'

//fonts
import './fonts/JeremyMoore.otf'
import './fonts/JeremyMoore.ttf'
import './fonts/JeremyMoore.woff'
import './fonts/JeremyMoore.woff2'
import './fonts/InterTight.ttf'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileScreen from './screens/ProfileScreen'
import { useSelector } from 'react-redux'
import { getUser } from './app/features/userSlice'
import EpisodeScreen from './screens/EpisodeScreen'
import RecoverPasswordScreen from './screens/RecoverPasswordScreen'
import EmailScreen from './screens/EmailScreen'
import SignInScreen from './screens/SignInScreen'
import SignUpScreen from './screens/SignUpScreen'
import PlanScreen from './screens/PlanScreen'
import UnpaidScreen from './screens/UnpaidScreen'

function App() {
  const user = useSelector(getUser)

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={!user ? <EmailScreen /> : <HomeScreen />} />
          <Route path="/signin" element={<SignInScreen />} />
          <Route path="/signup" element={<SignUpScreen />} />
          <Route path="/plan" element={<PlanScreen />} />
          <Route path="/unpaid" element={!user ? <EmailScreen /> : <UnpaidScreen />} />
          <Route path="/profile" element={!user ? <EmailScreen /> : <ProfileScreen />} />
          <Route path="/watch" element={!user ? <EmailScreen /> : <EpisodeScreen />} />
          <Route path="/recover-password" element={<RecoverPasswordScreen />} />
          <Route path="/recover-password/:token" element={<RecoverPasswordScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
