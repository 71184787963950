import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun } from '@fortawesome/free-solid-svg-icons'
import React from "react"

export default function Button(props) {
  const { text, isLoading, onClick } = props
  return (
    <>
      <button onClick={onClick} >
        {isLoading ?
          <FontAwesomeIcon icon={faSun} spin />
         : text}
      </button>
    </>
  )
}
