import api from "./api"
import { store } from "../app/store"

const getRequestOptions = () => {
  const storeValues = store.getState()
  const authToken = storeValues?.user?.user?.token
  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  }
}

const requests = {
  checkEmail: (email) => {
    return api.get(`/v1/check/${email}`)
  },
  register: (data) => {
    return api.post("/v1/register", data)
  },
  login: (data) => {
    return api.post("/v1/login", data)
  },
  logout: () => {
    return api.post("/v1/logout", {}, getRequestOptions())
  },
  getSections: (page, limit) => {
    return api.get(`/v1/sections?page=${page}&limit=${limit}`, getRequestOptions())
  },
  getPlans: () => {
    return api.get("/v1/plans")
  },
  createCheckoutSession: (data) => {
    return api.post("/v1/plans/checkout", data, getRequestOptions())
  },
  createPortalSession: (data) => {
    return api.post("/v1/plans/portal", data, getRequestOptions())
  },
  getUserSubscription: () => {
    return api.get("/v1/users/subscription", getRequestOptions())
  },
  setEpisodeWatched: (id, data) => {
    return api.put(`/v1/users/episodes/${id}/watched`, data, getRequestOptions())
  },
  createResetPassword: (data) => {
    return api.post("/v1/reset-password", data)
  },
  getResetPassword: (token) => {
    return api.get(`/v1/reset-password/${token}`)
  },
  resetPassword: (token, data) => {
    return api.put(`/v1/reset-password/${token}`, data)
  },
  updatePassword: (data) => {
    return api.put("/v1/update-password", data, getRequestOptions())
  },
}

export default requests
