import React, { useCallback, useEffect, useRef, useState } from "react"
import Nav from "../components/Nav"
import Banner from "../components/Banner"
import Row from "../components/Row"
import requests from "../api/requests"
import { logout } from "../app/features/userSlice"
import { useDispatch } from "react-redux"
import { useSnackbar } from "react-simple-snackbar"
import { errorSnackBar } from "../components/snackBarOptions"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "@mui/material"

function HomeScreen() {
  const [sections, setSections] = useState([])
  const [bannerEpisodes, setBannerEpisodes] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)

  const observer = useRef()
  const sectionRef = useCallback(
    (node, index) => {
      sectionsRef.current[index] = node

      if (sections.length === index + 1) {
        if (loading) return
        if (observer.current) observer.current.disconnect()

        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prev) => prev + 1)
          }
        })

        if (node) observer.current.observe(node)
      }
    },
    [sections, loading, hasMore],
  )

  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sectionsRef = useRef([])

  const scrollToSection = (index) => {
    const headerOffset = 80
    const elementPosition =
      sectionsRef.current[index]?.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerOffset

    window.scrollTo({ top: offsetPosition, behavior: "smooth" })
  }

  useEffect(() => {
    const handleAPIError = (error) => {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      if (error.response.status === 403) {
        const planStatus = error?.response?.data?.status
        if (planStatus === "not_subscribed") {
          navigate("/plan")
          return
        }

        navigate("/unpaid")
        return
      }

      openErrorSnackbar("Ocorreu um erro inexperado")
      return
    }

    async function fetchData() {
      try {
        setLoading(true)
        const req = await requests.getSections(page, 5)
        setSections((prev) => [...prev, ...req.data.sections])
        setHasMore(req.data.hasNextPage)
        if (page === 1) setBannerEpisodes(req.data.bannerEpisodes)

        return
      } catch (error) {
        handleAPIError(error)
      } finally {
        setLoading(false)
      }

      return
    }

    fetchData()
    return
  }, [page])

  return !loading ? (
    <div>
      <Nav sections={sections} scrollFunc={scrollToSection} hideSumary={false} />
      {bannerEpisodes && <Banner episodes={bannerEpisodes} />}
      {sections.map((section, i) => {
        if (section?.episodes?.length === 0) return null
        return (
          <Row
            key={i}
            index={section?._id}
            refFunc={(el) => sectionRef(el, i)}
            title={section?.title}
            contents={section?.episodes ? section?.episodes : section?.series}
          />
        )
      })}
    </div>
  ) : (
    <div style={{ padding: 20 }}>
      <Nav />
      <Skeleton height={"100vh"} width={"100%"} animation={"wave"} />
    </div>
  )
}

export default HomeScreen
