import { useState } from "react"
import "./FormInput.css"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const FormInput = (props) => {
  const [focused, setFocused] = useState(false)
  const { disabled, label, errorMessage, onChange, id, ...inputProps } = props

  const [type, setType] = useState(inputProps.type || "text")
  const [showPassword, setShowPassword] = useState(false)

  const isTypePassword = inputProps?.type === "password"

  delete inputProps?.type

  const togglePassword = () => {
    if (isTypePassword) {
      const newShowPassword = !showPassword;
      setShowPassword(newShowPassword);
      setType(newShowPassword ? "text" : "password");
    }
  }

  const handleFocus = (e) => {
    setFocused(true)
  }

  return (
    <div className="input-container">
      <input
        {...inputProps}
        autoFocus={inputProps.autofocus}
        type={type}
        onChange={onChange}
        onBlur={handleFocus}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
        disabled={!!disabled}
      />
      {isTypePassword && (
        <div className="password__icon" onClick={togglePassword}>
          {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </div>
      )}
      <span>{errorMessage}</span>
    </div>
    
  )
}

export default FormInput
